/** @format */

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  notification,
  Row,
  Spin,
  theme,
} from "antd";
import axios from "axios";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useState } from "react";
import responsiveColumn from "../../../methods/responsiveColumn";

const { useToken } = theme;

export default function HomeForm() {
  const { token } = useToken();
  const { useForm } = Form;

  const [loading, setLoading] = useState(false);

  const [form] = useForm();
  const { t } = useTranslation("home_form");

  const completeForm = async (values: any) => {
    const i = await form.validateFields();

    try {
      if (!i) {
        notification.open({
          message: t("notification_message"),
          type: "warning",
        });

        return;
      }

      setLoading(true);
      await axios({
        url: "/api/send_to_clio",
        method: "post",
        data: JSON.stringify(values),
        headers: {
          "content-type": "application/json",
        },
      });
      notification.open({ message: "Thank You", type: "success" });
      form.resetFields();
      setLoading(false);
    } catch (e) {
      notification.open({
        message: t("error_message"),
        type: "error",
      });
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Card
        style={{
          background: token.colorPrimary,
          width: "100%",
          color: "#fff",
          marginBottom: "30px",
        }}
        className='raleway'>
        <div style={{ fontSize: 22 }}>{t("form_title")}</div>
        <div style={{ marginTop: 10, fontSize: 15 }}>
          {t("form_contact_us")}
        </div>
        <Form
          onFinish={completeForm}
          form={form}
          style={{ marginTop: 30 }}
          layout='vertical'
          size='large'>
          <Row gutter={[15, 0]}>
            <Col {...responsiveColumn([12, 12, 12, 12, 12, 12])}>
              <Form.Item
                name='first_name'
                label={
                  <label style={{ color: "#fff" }}>{t("first_name")}</label>
                }
                rules={[
                  { required: true, min: 3, message: "First Name is Required" },
                ]}>
                <Input placeholder='ex: John' />
              </Form.Item>
            </Col>
            <Col {...responsiveColumn([12, 12, 12, 12, 12, 12])}>
              <Form.Item
                name='last_name'
                label={
                  <label style={{ color: "#fff" }}>{t("last_name")}</label>
                }
                rules={[
                  { required: true, min: 3, message: "Last Name is Required" },
                ]}>
                <Input placeholder='ex: Doe' />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={<label style={{ color: "#fff" }}>{t("email")}</label>}
                rules={[{ required: true, message: "Email is Required" }]}
                name='email'>
                <Input placeholder='ex myname@example.com' type='email' />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name='phone_number'
                rules={[
                  {
                    required: true,
                    message: "Phone Number is Required",
                  },
                ]}
                label={<label style={{ color: "#fff" }}>{t("phone")}</label>}>
                <Input placeholder='ex: (000) 000-0000' type='number' />
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item
                name='state'
                rules={[
                  {
                    required: true,
                    max: 14,
                    message: "State is Required",
                  },
                ]}
                label={<label style={{ color: "#fff" }}>{t("state")}</label>}>
                <Input placeholder='ex: Florida' />
              </Form.Item>
            </Col> */}
            <Col span={24}>
              <Form.Item
                name='message'
                rules={[
                  {
                    required: true,
                    message: "Message is Required",
                  },
                ]}
                label={
                  <label style={{ color: "#fff" }}>
                    {t("case")} <span style={{ color: "#dfdfdf" }}></span>
                  </label>
                }>
                <Input.TextArea
                  placeholder='Case Description'
                  style={{ height: 152 }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button
                  htmlType='submit'
                  type='default'
                  style={{
                    width: "100%",
                    background: "#DE9E36",
                    border: "#DE9E36",
                    color: "#fff",
                  }}>
                  {t("submit")}
                </Button>
              </Form.Item>
              <div style={{ color: "#FFFFFF" }}>
                This site is protected by reCAPTCHA and the Google{" "}
                <Link
                  target='_blank'
                  style={{ color: "#fff", fontWeight: "bold" }}
                  href='/privacy-policy'>
                  Privacy Policy{" "}
                </Link>
                and Terms of Service apply.
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
    </Spin>
  );
}
