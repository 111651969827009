/** @format */

import { Layout } from "antd";
import type { GetStaticProps } from "next";
import Awards from "../components/Awards";
import Section_1 from "../components/home/Section_1";
import Section_2 from "../components/home/Section_2";
import Section_3 from "../components/home/Section_3";
import Section_4 from "../components/home/Section_4";
import Section_5 from "../components/home/Section_5";
import Section_6 from "../components/home/Section_6";
import Section_7 from "../components/home/Section_7";
import Section_8 from "../components/home/Section_8";
import VideoComponent from "../components/home/VideoComponent";
import HomeFooter from "../components/HomeFooter";
import SEO from "../components/seo";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import { HeadScriptTag } from "../components/HeadScriptTag";

export interface VideoComponentProps {
  videoURL: string;
}

//tag id of "home" is 24 in the wordpress dashboard
export const homeTagId = 24;

const Home = ({ videoURL }: VideoComponentProps) => {
  const { t } = useTranslation("meta_tags");

  const title = t("home");
  const description = t("home_desc");
  const keywords =
    "Tax Bankruptcy, Tax Controversy, Tax Compliance, Criminal Tax Defense";
  // const ogImage = "https://www.example.com/static/images/og-image.jpg";
  const ogType = "website";
  const canonicalURL = "https://taxworkoutgroup.com";
  return (
    <>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        // ogImage={ogImage}
        ogType={ogType}
        canonicalURL={canonicalURL}
      />
      <HeadScriptTag />
      <Layout.Content>
        <VideoComponent videoURL={videoURL} />
        <Section_1 />
        <Section_2 />
        <Section_3 />
        <Section_4 />
        <Section_5 />
        <Section_6 />
        <Awards />
        <Section_7 />
        <Section_8 />
      </Layout.Content>
      <Layout.Footer style={{ padding: 0 }}>
        <HomeFooter />
      </Layout.Footer>
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const videoURL =
    "https://wp.taxworkoutgroup.com/wp-content/uploads/2023/11/twg_home_page_video_compressed.mp4";
  const translations = await serverSideTranslations(locale as string);
  return {
    props: {
      videoURL,
      ...translations,
    },
  };
};

export default Home;
